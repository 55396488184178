import {defineStore} from "pinia";

// import { useRoute } from 'vue-router';
//
// const route = useRoute();
// console.log(route.query);

const useAppConfigStore = defineStore('globalConfig', {
	state: () => ({
		isLoading: true,
		loadedLanguage: 'de',
		texts: {
			alerts: {
				required: '',
				minLength3: '',
				selectArea: '',
				insertValue: '',
				insertNumericValue: '',
				validEmail: '',
			},
			buttons: {
				closeBtn: '',
				createPDFbtn: '',
				nextBtn: '',
				previousBtn: '',
			},
			columnHeaders: {
				percentChange: '',
				value: '',
				comments: '',
			},
			mainMenu: {
				menuItem_1: '',
				menuItem_2: '',
				menuItem_3: '',
				menuItem_4: '',
				menuItem_5: '',
				menuItem_6: '',
			},
			introduction: {
				menuText: '',
				text: '',
				title: '',
				buttonText: ''
			},
			settings: {
				information: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
					label_6: '',
					label_7: '',
				},
				electricityMix: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
					label_6: '',
					label_7: '',
					label_8: '',
				},
				distributionOfWaterSources: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
				},
			},
			input: {
				authorizedConsumption: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
				},
				calculationPeriod: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
				},
				emissionAndElectricityData: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
				},
				leakage: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
				},
				nonRevenueWater: {
					menuName: '',
					title: '',
					label_1: '',
				},
				pipelineLifetime: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
					label_6: '',
					label_7: '',
					label_8: '',
				},
				pressureData: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
				},
				systemData: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
					label_6: '',
					label_7: '',
				},
				waterLosses: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
					label_6: '',
					label_7: '',
				},
				waterSupplied: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
				},
			},
			pressureManagement: {
				effectsOnPipelineLifetime: {
					menuName: '',
					title: '',
					infotext: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
					label_6: '',
					label_7: '',
					label_8: '',
					label_9: '',
					label_10: '',
					label_11: '',
				},
				effectsOnRevenueCostsAndCo2Emissions: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
					label_6: '',
					label_7: '',
					label_8: '',
				},
				leakageLevelWaterConsumptionAndElectricityConsumption: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
				},
				pressuremanagementInput: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
				},
			},
			pipeBursts: {
				burstsAfterPressureManagement: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
					label_6: '',
				},
				effectsOnRevenueCostsAndCo2EmissionsPipeBursts: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
					label_6: '',
					label_7: '',
					label_8: '',
					label_9: '',
					label_10: '',
				},
				pipeBurstsBeforePressureManagement: {
					menuName: '',
					title: '',
					label_1: '',
					label_2: '',
					label_3: '',
					label_4: '',
					label_5: '',
				},
			},
			results: {
				menuName: '',
				title: '',
			}
		},
		areas: [{
			_id: '',
			areaName: '',
			fromOil: 0,
			fromCoal: 0,
			fromGas: 0,
			fromBiomass: 0,
			fromRenewables: 0,
			fromNuclearpower: 0,
			fromOther: 0,
		}],
		currencies: [{
			_id: '',
			isoName: '',
			exchangeRate: 0,
			comment: '',
		}],
		flowUnits: [{
			_id: '',
			unit: '',
			factor: 0,
			comment: '',
		}],
		pressureUnits: [{
			_id: '',
			unit: '',
			factor: 0,
			comment: '',
		}],
		commonLocalizations: {
			dateFormat: 'MM/dd/yyyy',
		},
		error: {}
	}),
	getters: {
		getLoadingState: (state) => {
			return state.isLoading;
		},
		getIntroduction: (state) => {
			return state.texts.introduction;
		},
		getAlerts: (state) => {
			return state.texts.alerts;
		},
	},
	actions: {
		async fetchData(lang: string = 'de') {
			console.log('loading lang:', lang);
			this.loadedLanguage = lang;
			this.isLoading = true;
			let query = `{
			  alertsModel(locale:"`+ lang +`"){
				required
				minLength3
				selectArea
				insertValue
				insertNumericValue
				validEmail
			  }
			  buttonsModel(locale:"`+ lang +`"){
				closeBtn
				createPDFbtn
				nextBtn
				previousBtn
			  }
			  columnHeadersModel(locale:"`+ lang +`"){
				percentChange
				value
				comments
			  }
			  mainMenuModel(locale:"`+ lang +`"){
				menuItem_1
				menuItem_2
				menuItem_3
				menuItem_4
				menuItem_5
				menuItem_6
			  }
			  appSettingsModel(locale:"`+ lang +`"){
				dialogTitle
				tab_1
				tab_2
				tab_3
				tab_4
			  }
			  authorizedConsumptionModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
				label_5
			  }
			  burstsAfterPressureManagementModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
				label_5
				label_6
			  }
			  calculationPeriodModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
			  }
			  distributionOfWaterSourcesModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
			  }
			  effectsOnPipelineLifetimeModel(locale:"`+ lang +`"){
				menuName
				title
				infotext
				label_1
				label_2
				label_3
				label_4
				label_5
				label_6
				label_7
				label_8
				label_9
				label_10
				label_11
			  }
			  effectsOnRevenueCostsAndCo2EmissionsModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
				label_5
				label_6
				label_7
				label_8
			  }
			  effectsOnRevenueCostsAndCo2EmissionsPipeBurstsModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
				label_5
				label_6
				label_7
				label_8
				label_9
				label_10
			  }
			  electricityMixModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
				label_5
				label_6
				label_7
				label_8
			  }
			  emissionAndElectricityDataModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
			  }
			  informationModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
				label_5
				label_6
				label_7
			  }
			  introductionModel(locale:"`+ lang +`"){
				menuName
				title
				text
				buttonText
			  }
			  leakageModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
			  }
			  leakageLevelWaterConsumptionAndElectricityConsumptionModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
				label_5
			  }
			  nonRevenueWaterModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
			  }
			  pipeBurstsBeforePressureManagementModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
				label_5
			  }
			  pipelineLifetimeModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
				label_5
				label_6
				label_7
				label_8
			  }
			  pressureDataModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
			  }
			  pressuremanagementInputModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
				label_5
			  }
			  resultsModel(locale:"`+ lang +`"){
				menuName
				title
			  }
			  systemDataModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
				label_5
				label_6
				label_7
			  }
			  waterLossesModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
				label_5
				label_6
				label_7
			  }
			  waterSuppliedModel(locale:"`+ lang +`"){
				menuName
				title
				label_1
				label_2
				label_3
				label_4
			  }
			  currencyModel(locale:"`+ lang +`"){
				_id
				isoName
				exchangeRate
				comment
			  }
			  pressureUnitModel(locale:"`+ lang +`"){
				_id
				unit
				factor
				comment
			  }
			  flowUnitModel(locale:"`+ lang +`"){
				_id
				unit
				factor
				comment
			  }
			  commonLocalizationsModel(locale:"`+ lang +`"){
				dateFormat
			  }
			  areaModel(locale:"`+ lang +`"){
				_id
				areaName
				fromOil
				fromCoal
				fromGas
				fromBiomass
				fromRenewables
				fromNuclearpower
				fromOther
				status
				source
				link
			  }
			}`
			try {
				let response = await fetch('https://neoflow.gfpstools.com/api/gql', {
					method: 'POST',
					headers: {
						'content-type': 'application/json'
					},
					body: JSON.stringify({query}),
				})
					.then(response => response.json());
				this.texts.alerts = response.data['alertsModel'];
				this.texts.buttons = response.data['buttonsModel'];
				this.texts.columnHeaders = response.data['columnHeadersModel'];
				this.texts.mainMenu = response.data['mainMenuModel'];
				this.texts.introduction = response.data['introductionModel'];
				this.texts.settings.information = response.data['informationModel'];
				this.texts.settings.electricityMix = response.data['electricityMixModel'];
				this.texts.settings.distributionOfWaterSources = response.data['distributionOfWaterSourcesModel'];
				this.texts.input.authorizedConsumption = response.data['authorizedConsumptionModel'];
				this.texts.input.calculationPeriod = response.data['calculationPeriodModel'];
				this.texts.input.emissionAndElectricityData = response.data['emissionAndElectricityDataModel'];
				this.texts.input.leakage = response.data['leakageModel'];
				this.texts.input.nonRevenueWater = response.data['nonRevenueWaterModel'];
				this.texts.input.pipelineLifetime = response.data['pipelineLifetimeModel'];
				this.texts.input.pressureData = response.data['pressureDataModel'];
				this.texts.input.systemData = response.data['systemDataModel'];
				this.texts.input.waterLosses = response.data['waterLossesModel'];
				this.texts.input.waterSupplied = response.data['waterSuppliedModel'];
				this.texts.pressureManagement.effectsOnPipelineLifetime = response.data['effectsOnPipelineLifetimeModel'];
				this.texts.pressureManagement.effectsOnRevenueCostsAndCo2Emissions = response.data['effectsOnRevenueCostsAndCo2EmissionsModel'];
				this.texts.pressureManagement.pressuremanagementInput = response.data['pressuremanagementInputModel'];
				this.texts.pressureManagement.leakageLevelWaterConsumptionAndElectricityConsumption = response.data['leakageLevelWaterConsumptionAndElectricityConsumptionModel'];
				this.texts.pipeBursts.burstsAfterPressureManagement = response.data['burstsAfterPressureManagementModel'];
				this.texts.pipeBursts.effectsOnRevenueCostsAndCo2EmissionsPipeBursts = response.data['effectsOnRevenueCostsAndCo2EmissionsPipeBurstsModel'];
				this.texts.pipeBursts.pipeBurstsBeforePressureManagement = response.data['pipeBurstsBeforePressureManagementModel'];
				this.texts.results = response.data['resultsModel'];
				this.areas = response.data['areaModel'];
				this.currencies = response.data['currencyModel'];
				this.flowUnits = response.data['flowUnitModel'];
				this.commonLocalizations = response.data['commonLocalizationsModel'];
				this.pressureUnits = response.data['pressureUnitModel'];
			} catch (err) {
				return err;
			} finally {
				this.isLoading = false;
			}
		},
	}

})

export default useAppConfigStore;
